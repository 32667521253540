import api from '../library/axios';

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    company_id,
  }) {
    return api({
      method: 'get',
      url: `/api/contact-group/${workspace_id}`,
      params: {
        page,
        search,
        search_by,
        company_id,
      },
    });
  },
  detail({
    workspace_id,
    id,
  }) {
    return api({
      method: 'get',
      url: `/api/contact-group/${workspace_id}/${id}/detail`,
    });
  },
  create({
    workspace_id,
    data,
  }) {
    return api({
      method: 'post',
      url: `/api/contact-group/${workspace_id}`,
      data,
    });
  },
  update({
    workspace_id,
    id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/contact-group/${workspace_id}/${id}`,
      data,
    });
  },
  delete({
    workspace_id,
    id,
  }) {
    return api({
      method: 'delete',
      url: `/api/contact-group/${workspace_id}/${id}`,
    });
  },
  getListContact({
    group_id,
    page,
    search,
    search_by,
    label_id,
    company_id,
  }) {
    return api({
      method: 'get',
      url: `/api/contact-group/contact-list/${group_id}`,
      params: {
        page,
        search,
        search_by,
        label_id,
        company_id,
      },
    });
  },
  addListContact(data) {
    return api({
      method: 'post',
      url: '/api/contact-group/contact-list',
      data,
    });
  },
  removeListContact(data) {
    return api({
      method: 'delete',
      url: '/api/contact-group/contact-list',
      data,
    });
  },
};
