import api from '../library/axios';
import config from '../config';

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    company_id,
    label_id,
    group_id,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}`,
      params: {
        page,
        search,
        search_by,
        company_id,
        label_id,
        group_id,
      },
    });
  },
  getListBlockContact({
    workspace_id,
    page,
    search,
    search_by,
    company_id,
    label_id,
    group_id,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}/block/list`,
      params: {
        page,
        search,
        search_by,
        company_id,
        label_id,
        group_id,
      },
    });
  },
  getListAll({
    workspace_id,
    page,
    search,
    search_by,
  }) {
    return api({
      method: 'get',
      url: `/api/all-contacts/${workspace_id}`,
      params: {
        page,
        search,
        search_by,
      },
    });
  },
  detail({
    workspace_id,
    id,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}/${id}/detail`,
    });
  },
  update({
    workspace_id,
    id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/contacts/${workspace_id}/${id}`,
      data,
    });
  },
  mergeContact({
    workspace_id,
    target_id,
    parent_id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/contacts/merge/${workspace_id}`,
      data: {
        target_id,
        parent_id,
      },
    });
  },
  blockContact({
    contact_id,
    blocked_reason,
  }) {
    return api({
      method: 'post',
      url: '/api/contacts/block',
      data: {
        contact_id,
        blocked_reason,
      },
    });
  },
  unblockContact({
    contact_id,
  }) {
    return api({
      method: 'post',
      url: '/api/contacts/unblock',
      data: {
        contact_id,
      },
    });
  },
  importContact(workspace_id, data) {
    return api({
      method: 'post',
      url: `/api/contacts/${workspace_id}/bulk`,
      data,
    });
  },
  downloadContactList({
    workspace_id,
    company_id,
    token,
    label_id,
    group_id,
    delimiter,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}/download?company_id=${company_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        label_id,
        group_id,
      },
      data: {
        delimiter,
      },
    });
  },
};
